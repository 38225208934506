<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            <!-- {{ $t("title.game") }} -->
            {{ event.name }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div
            class="text-h6 text-center"
            style="color: #a88e6c"
            v-if="eventStatus == 'ONGOING'"
          >
            {{ $t("string.ends_in") }} {{ countdown }}
          </div>
          <div
            class="text-h6 text-center"
            style="color: #a88e6c"
            v-else-if="eventStatus == 'UPCOMING'"
          >
            {{ $t("string.starts_in") }} {{ countdown }}
          </div>
          <div
            class="text-h6 text-center"
            style="color: #a88e6c"
            v-else-if="eventStatus == 'LONGUPCOMING'"
          >
            {{ $t("string.coming_on") }}
            {{ $moment(event.timestampStart).format("MMM D") }}
          </div>
          <div class="text-h6 text-center" style="color: #a88e6c" v-else>
            {{ $t("string.event_over") }}
          </div>
          <div class="nav-panel">
            <div
              v-for="(nav, j) in navs"
              :key="j"
              :class="nav.name == $route.name ? 'selected-nav' : 'nav'"
            >
              <span
                class="nav-img"
                :class="
                  nav.active
                    ? nav.name == $route.name
                      ? nav.classImg + ' nav-img-selected'
                      : nav.classImg
                    : 'nav-img-deactive'
                "
              ></span>

              <v-img
                v-if="nav.icon"
                :src="nav.icon"
                max-width="100%"
                :class="!nav.active ? 'deactive' : ''"
                class="justify-center align-center text-center nav-image"
                :style="
                  nav.name == $route.name
                    ? 'padding-bottom: 30%'
                    : 'padding-bottom: 40%'
                "
                contain
                @click="changeTab(nav.name)"
              >
                <div
                  class="nav-text"
                  style="
                    position: absolute;
                    bottom: 0;
                    text-align: center;
                    width: 100%;
                    color: #fff;
                  "
                  :class="
                    nav.name == $route.name ? 'nav-text-selected' : nav.classTxt
                  "
                >
                  {{ nav.text }}
                </div>
              </v-img>
              <v-img
                v-else
                max-width="100%"
                :class="!nav.active ? 'deactive' : ''"
                class="justify-center align-center text-center nav-image"
                :style="
                  nav.name == $route.name
                    ? 'padding-bottom: 30%'
                    : 'padding-bottom: 40%'
                "
                contain
                @click="changeTab(nav.name)"
              >
                <v-avatar
                  color="green"
                  :size="nav.name == $route.name ? 70 : 50"
                  :class="nav.name == $route.name ? 'my-2' : 'mb-0'"
                  class="text-uppercase white--text"
                >
                  {{
                    auth.Institution ? auth.Institution.name.slice(0, 2) : "SC"
                  }}
                </v-avatar>
                <div
                  class="nav-text"
                  style="
                    position: absolute;
                    bottom: 0;
                    text-align: center;
                    width: 100%;
                    color: #fff;
                  "
                  :class="
                    nav.name == $route.name ? 'nav-text-selected' : nav.classTxt
                  "
                >
                  {{ nav.text }}
                </div>
              </v-img>
            </div>
          </div>
          <router-view :key="$route.fullPath"></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    countdown() {
      return this.remainingTime;
    },
  }),
  props: [
    //
  ],
  data: () => ({
    intraction: null,
    eventStatus: "",
    remainingTime: "",
    endTime: "",
    event: {},
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    schoolCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    navs: [],
  }),
  created() {
    this.api.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/" +
      this.$route.params.code;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.event = resp;
      this.eventStatus = this.status(
        this.event.timestampStart,
        this.event.timestampEnd
      );

      this.navs = [
        {
          icon:
            this.auth.Institution && this.auth.Institution.logoUrl
              ? this.auth.Institution.logoUrl
              : require("@/assets/explore/school.png"),
          name: "PageEventSchool",
          text: this.$t("string.school"),
          // active:
          //   this.eventStatus == "ONGOING" &&
          //   this.auth.Group.type == "institution"
          //     ? true
          //     : false,
          active: this.auth.Group.type == "institution" ? true : false,
          classImg: "nav-img-sch",
          classTxt: "nav-text-sch",
        },
        {
          icon: require("@/assets/explore/rank.png"),
          name: "PageEventRank",
          text: this.$t("string.rank"),
          active:
            this.eventStatus == "PAST" || this.eventStatus == "ONGOING"
              ? true
              : false,
          // active: true,
          classImg: "nav-img-rank",
          classTxt: "nav-text-rank",
        },
        {
          icon: require("@/assets/explore/level.png"),
          name: "PageEventLevels",
          text: this.$t("string.levels"),
          active: this.eventStatus == "ONGOING" ? true : false,
          // active: true,
          classImg: "nav-img-level",
          classTxt: "nav-text-level",
        },
        {
          icon: require("@/assets/explore/info.png"),
          name: "PageEventInfo",
          text: this.$t("string.info"),
          // active: this.eventStatus != "LONGUPCOMING" ? true : false,
          active: true,
          classImg: "nav-img-info",
          classTxt: "nav-text-info",
        },
      ];

      this.endTime =
        this.eventStatus == "UPCOMING"
          ? this.event.timestampStart
          : this.event.timestampEnd;
      this.api.isLoading = false;
    };
    this.api.method = "GET";
  },
  mounted() {
    this.$api.fetch(this.api);
    this.loadSchoolYear();
    this.updateCountdown();
    setInterval(() => {
      this.updateCountdown();
    }, 1000);
  },
  methods: {
    updateCountdown() {
      const end = this.$moment(this.endTime);
      const now = this.$moment();
      const duration = this.$moment.duration(end.diff(now));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      this.remainingTime = `${days}D ${hours}H ${minutes}M ${seconds}S`;
    },
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    changeTab(name) {
      this.$router.push({
        name: name,
      });
    },
    loadSchoolYear() {
      this.schoolCheckApi.url =
        this.$api.servers.sso +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/student/loadSchoolYear";

      this.schoolCheckApi.callbackReset = () => {
        this.schoolCheckApi.isLoading = true;
        this.schoolCheckApi.isError = false;
      };

      this.schoolCheckApi.callbackError = (e) => {
        this.schoolCheckApi.isLoading = false;
        this.schoolCheckApi.isError = true;
        this.schoolCheckApi.error = e;
      };
      this.schoolCheckApi.callbackSuccess = (resp) => {
        if (
          this.auth.Classroom &&
          this.auth.Classroom.schoolYear &&
          resp.schoolYear &&
          this.auth.Classroom.schoolYear != resp.schoolYear
        ) {
          this.authData = this.$_.cloneDeep(this.auth);
          this.authData.Classroom.schoolYear = resp.schoolYear;
          this.$store.commit("updateAuth", this.authData);
          this.$router.go();
        } else if (
          this.auth.User &&
          this.auth.User.schoolYear &&
          resp.schoolYear &&
          this.auth.User.schoolYear != resp.schoolYear
        ) {
          this.authData = this.$_.cloneDeep(this.auth);
          this.authData.User.schoolYear = resp.schoolYear;
          this.$store.commit("updateAuth", this.authData);
          this.$router.go();
        }
      };
      this.schoolCheckApi.params = {
        mochiId: this.auth.Player.mochiId,
        eventCode: this.$route.params.code,
        schoolYear: this.auth.Classroom
          ? this.auth.Classroom.schoolYear
          : this.auth.User.schoolYear,
      };
      this.$api.fetch(this.schoolCheckApi);
    },
    status(timestampStart, timestampEnd) {
      if (this.$moment().isAfter(timestampEnd)) {
        return "PAST";
      } else if (this.$moment().isAfter(timestampStart)) {
        return "ONGOING";
      } else if (
        this.$moment(timestampStart).diff(this.$moment(), "days") > 100
      ) {
        return "LONGUPCOMING";
      } else {
        return "UPCOMING";
      }
    },
  },
};
</script>
  
  <style scoped>
.topic {
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  background-color: #e3ceb1;
}
.nav {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 23%;
  padding: 0 2%;
  font-size: small;
  justify-content: center;
  position: relative !important;
}

.nav-img-selected {
  border: #51fff4 5px solid !important;
}

.nav-text-selected {
  text-shadow: #23c4bc 5px 0px 0px, #23c4bc 4.90033px 0.993347px 0px,
    #23c4bc 4.60531px 1.94709px 0px, #23c4bc 4.12668px 2.82321px 0px,
    #23c4bc 3.48353px 3.58678px 0px, #23c4bc 2.70151px 4.20735px 0px,
    #23c4bc 1.81179px 4.6602px 0px, #23c4bc 0.849836px 4.92725px 0px,
    #23c4bc -0.145998px 4.99787px 0px, #23c4bc -1.13601px 4.86924px 0px,
    #23c4bc -2.08073px 4.54649px 0px, #23c4bc -2.94251px 4.04248px 0px,
    #23c4bc -3.68697px 3.37732px 0px, #23c4bc -4.28444px 2.57751px 0px,
    #23c4bc -4.71111px 1.67494px 0px, #23c4bc -4.94996px 0.7056px 0px,
    #23c4bc -4.99147px -0.291871px 0px, #23c4bc -4.83399px -1.27771px 0px,
    #23c4bc -4.48379px -2.2126px 0px, #23c4bc -3.95484px -3.05929px 0px,
    #23c4bc -3.26822px -3.78401px 0px, #23c4bc -2.4513px -4.35788px 0px,
    #23c4bc -1.53666px -4.75801px 0px, #23c4bc -0.560763px -4.96846px 0px,
    #23c4bc 0.437495px -4.98082px 0px, #23c4bc 1.41831px -4.79462px 0px,
    #23c4bc 2.34258px -4.41727px 0px, #23c4bc 3.17346px -3.86382px 0px,
    #23c4bc 3.87783px -3.15633px 0px, #23c4bc 4.4276px -2.32301px 0px,
    #23c4bc 4.80085px -1.39708px 0px, #23c4bc 4.98271px -0.415447px 0px;
}
.nav-img-level {
  border: #fff 5px solid;
  background-color: #fffa7c;
}

.nav-text-level {
  text-shadow: #fcc649 3px 0px 0px, #fcc649 2.83487px 0.981584px 0px,
    #fcc649 2.35766px 1.85511px 0px, #fcc649 1.62091px 2.52441px 0px,
    #fcc649 0.705713px 2.91581px 0px, #fcc649 -0.287171px 2.98622px 0px,
    #fcc649 -1.24844px 2.72789px 0px, #fcc649 -2.07227px 2.16926px 0px,
    #fcc649 -2.66798px 1.37182px 0px, #fcc649 -2.96998px 0.42336px 0px,
    #fcc649 -2.94502px -0.571704px 0px, #fcc649 -2.59586px -1.50383px 0px,
    #fcc649 -1.96093px -2.27041px 0px, #fcc649 -1.11013px -2.78704px 0px,
    #fcc649 -0.137119px -2.99686px 0px, #fcc649 0.850987px -2.87677px 0px,
    #fcc649 1.74541px -2.43999px 0px, #fcc649 2.44769px -1.73459px 0px,
    #fcc649 2.88051px -0.838247px 0px;
}
.nav-img-info {
  border: #fff 5px solid;
  background-color: #a3e0fb;
}

.nav-text-sch {
  text-shadow: #3abc4f 3px 0px 0px, #3abc4f 2.83487px 0.981584px 0px,
    #3abc4f 2.35766px 1.85511px 0px, #3abc4f 1.62091px 2.52441px 0px,
    #3abc4f 0.705713px 2.91581px 0px, #3abc4f -0.287171px 2.98622px 0px,
    #3abc4f -1.24844px 2.72789px 0px, #3abc4f -2.07227px 2.16926px 0px,
    #3abc4f -2.66798px 1.37182px 0px, #3abc4f -2.96998px 0.42336px 0px,
    #3abc4f -2.94502px -0.571704px 0px, #3abc4f -2.59586px -1.50383px 0px,
    #3abc4f -1.96093px -2.27041px 0px, #3abc4f -1.11013px -2.78704px 0px,
    #3abc4f -0.137119px -2.99686px 0px, #3abc4f 0.850987px -2.87677px 0px,
    #3abc4f 1.74541px -2.43999px 0px, #3abc4f 2.44769px -1.73459px 0px,
    #3abc4f 2.88051px -0.838247px 0px;
}
.nav-img-sch {
  border: #fff 5px solid;
  background-color: #7dd58c;
}

.nav-text-info {
  text-shadow: #83b0c7 3px 0px 0px, #83b0c7 2.83487px 0.981584px 0px,
    #83b0c7 2.35766px 1.85511px 0px, #83b0c7 1.62091px 2.52441px 0px,
    #83b0c7 0.705713px 2.91581px 0px, #83b0c7 -0.287171px 2.98622px 0px,
    #83b0c7 -1.24844px 2.72789px 0px, #83b0c7 -2.07227px 2.16926px 0px,
    #83b0c7 -2.66798px 1.37182px 0px, #83b0c7 -2.96998px 0.42336px 0px,
    #83b0c7 -2.94502px -0.571704px 0px, #83b0c7 -2.59586px -1.50383px 0px,
    #83b0c7 -1.96093px -2.27041px 0px, #83b0c7 -1.11013px -2.78704px 0px,
    #83b0c7 -0.137119px -2.99686px 0px, #83b0c7 0.850987px -2.87677px 0px,
    #83b0c7 1.74541px -2.43999px 0px, #83b0c7 2.44769px -1.73459px 0px,
    #83b0c7 2.88051px -0.838247px 0px;
}
.nav-img-rank {
  border: #fff 5px solid;
  background-color: #f9a6a6;
}
.nav-text-rank {
  text-shadow: #ca6b6a 3px 0px 0px, #ca6b6a 2.83487px 0.981584px 0px,
    #ca6b6a 2.35766px 1.85511px 0px, #ca6b6a 1.62091px 2.52441px 0px,
    #ca6b6a 0.705713px 2.91581px 0px, #ca6b6a -0.287171px 2.98622px 0px,
    #ca6b6a -1.24844px 2.72789px 0px, #ca6b6a -2.07227px 2.16926px 0px,
    #ca6b6a -2.66798px 1.37182px 0px, #ca6b6a -2.96998px 0.42336px 0px,
    #ca6b6a -2.94502px -0.571704px 0px, #ca6b6a -2.59586px -1.50383px 0px,
    #ca6b6a -1.96093px -2.27041px 0px, #ca6b6a -1.11013px -2.78704px 0px,
    #ca6b6a -0.137119px -2.99686px 0px, #ca6b6a 0.850987px -2.87677px 0px,
    #ca6b6a 1.74541px -2.43999px 0px, #ca6b6a 2.44769px -1.73459px 0px,
    #ca6b6a 2.88051px -0.838247px 0px;
}
.nav .nav-img {
  height: 60%;
  width: 70%;
  margin: 32% 15% 8% 15%;
  position: absolute;
}
.nav-img-deactive {
  border: #eaeaea 5px solid;
  background-color: rgb(191, 191, 191);
  text-shadow: #898989 3px 0px 0px, #898989 2.83487px 0.981584px 0px,
    #898989 2.35766px 1.85511px 0px, #898989 1.62091px 2.52441px 0px,
    #898989 0.705713px 2.91581px 0px, #898989 -0.287171px 2.98622px 0px,
    #898989 -1.24844px 2.72789px 0px, #898989 -2.07227px 2.16926px 0px,
    #898989 -2.66798px 1.37182px 0px, #898989 -2.96998px 0.42336px 0px,
    #898989 -2.94502px -0.571704px 0px, #898989 -2.59586px -1.50383px 0px,
    #898989 -1.96093px -2.27041px 0px, #898989 -1.11013px -2.78704px 0px,
    #898989 -0.137119px -2.99686px 0px, #898989 0.850987px -2.87677px 0px,
    #898989 1.74541px -2.43999px 0px, #898989 2.44769px -1.73459px 0px,
    #898989 2.88051px -0.838247px 0px;
}

.selected-nav {
  cursor: pointer;
  font-size: x-large !important;
  display: flex;
  align-items: center;
  width: 31%;
  padding: 0 3px;
  justify-content: center;
  pointer-events: none;
  position: relative !important;
}

.selected-nav .nav-img {
  height: 80%;
  width: 76%;
  margin: 30% 12% 0% 12%;
  position: absolute;
}

.nav-panel {
  display: flex;
  padding: 0px 5px;
  margin-top: -20px;
  margin-bottom: 25px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 70px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  background-color: rgba(214, 132, 49, 0.3);
}

.text-brd {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.selected-nav .nav-image {
  margin-bottom: -25px;
}

.nav .nav-image {
  margin-bottom: -15px;
}
.menu-button {
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.game-card {
  border-radius: 5px;
  border: #8b5629 5px solid;
  margin: 10px;
  background-color: #fecb8a;
  text-align: center;
}

.game-title {
  color: #8b5629;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.deactive {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.7;
}
</style>