var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body",style:(_vm.intraction)},[_c('v-container',{staticClass:"app-body"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.event.name)+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),(_vm.eventStatus == 'ONGOING')?_c('div',{staticClass:"text-h6 text-center",staticStyle:{"color":"#a88e6c"}},[_vm._v(" "+_vm._s(_vm.$t("string.ends_in"))+" "+_vm._s(_vm.countdown)+" ")]):(_vm.eventStatus == 'UPCOMING')?_c('div',{staticClass:"text-h6 text-center",staticStyle:{"color":"#a88e6c"}},[_vm._v(" "+_vm._s(_vm.$t("string.starts_in"))+" "+_vm._s(_vm.countdown)+" ")]):(_vm.eventStatus == 'LONGUPCOMING')?_c('div',{staticClass:"text-h6 text-center",staticStyle:{"color":"#a88e6c"}},[_vm._v(" "+_vm._s(_vm.$t("string.coming_on"))+" "+_vm._s(_vm.$moment(_vm.event.timestampStart).format("MMM D"))+" ")]):_c('div',{staticClass:"text-h6 text-center",staticStyle:{"color":"#a88e6c"}},[_vm._v(" "+_vm._s(_vm.$t("string.event_over"))+" ")]),_c('div',{staticClass:"nav-panel"},_vm._l((_vm.navs),function(nav,j){return _c('div',{key:j,class:nav.name == _vm.$route.name ? 'selected-nav' : 'nav'},[_c('span',{staticClass:"nav-img",class:nav.active
                  ? nav.name == _vm.$route.name
                    ? nav.classImg + ' nav-img-selected'
                    : nav.classImg
                  : 'nav-img-deactive'}),(nav.icon)?_c('v-img',{staticClass:"justify-center align-center text-center nav-image",class:!nav.active ? 'deactive' : '',style:(nav.name == _vm.$route.name
                  ? 'padding-bottom: 30%'
                  : 'padding-bottom: 40%'),attrs:{"src":nav.icon,"max-width":"100%","contain":""},on:{"click":function($event){return _vm.changeTab(nav.name)}}},[_c('div',{staticClass:"nav-text",class:nav.name == _vm.$route.name ? 'nav-text-selected' : nav.classTxt,staticStyle:{"position":"absolute","bottom":"0","text-align":"center","width":"100%","color":"#fff"}},[_vm._v(" "+_vm._s(nav.text)+" ")])]):_c('v-img',{staticClass:"justify-center align-center text-center nav-image",class:!nav.active ? 'deactive' : '',style:(nav.name == _vm.$route.name
                  ? 'padding-bottom: 30%'
                  : 'padding-bottom: 40%'),attrs:{"max-width":"100%","contain":""},on:{"click":function($event){return _vm.changeTab(nav.name)}}},[_c('v-avatar',{staticClass:"text-uppercase white--text",class:nav.name == _vm.$route.name ? 'my-2' : 'mb-0',attrs:{"color":"green","size":nav.name == _vm.$route.name ? 70 : 50}},[_vm._v(" "+_vm._s(_vm.auth.Institution ? _vm.auth.Institution.name.slice(0, 2) : "SC")+" ")]),_c('div',{staticClass:"nav-text",class:nav.name == _vm.$route.name ? 'nav-text-selected' : nav.classTxt,staticStyle:{"position":"absolute","bottom":"0","text-align":"center","width":"100%","color":"#fff"}},[_vm._v(" "+_vm._s(nav.text)+" ")])],1)],1)}),0),_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }